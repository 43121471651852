html{
  font-size: 0.625vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, Source Han Sans CN, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
}

p,h1,h2,h3,a,div,span{
  user-select: none;
  margin: 0;
}
p,span{
  text-align: justify;
}
.floatJoin{
  position: fixed;
  right: 0.2rem;
  z-index: 10;
  cursor: pointer;
  display: block;
  width: 8rem;
  top: 50%;
  margin-top: -8.2rem;
  transition: all linear 0.3s;
  transform-origin: top center;
  &:hover{
    transform: scale(1.03);
  }
}
